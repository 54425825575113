/*

=========================================================
* Now UI Kit React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-react
* Copyright 2020 Creative Tim (http://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-kit-react/blob/master/LICENSE.md)

* Designed by www.invisionapp.com Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// pages for this kit
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LoginPage from "views/examples/LoginPage.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Privacy from "views/Privacy";
import GooglePicker from "views/GooglePicker";
import GooglePickerAirPrint from "views/GooglePickerAirPrint";
import OneDrivePicker from "views/OneDrivePicker";
import AirPrinterPrivacy from "views/AirPrinterPrivacy";
import AirPrinterTerms from "views/AirPrinterTerms";
import DataMigrationPrivacy from "views/DataMigrationPrivacy";
import DataMigrationTerms from "views/DataMigrationTerms";

ReactDOM.render(
  <BrowserRouter>
      <Switch>
      <Route
          path="/privacy"
          render={(props) => <Privacy {...props} />}
        />

        <Route
          path="/airprinter/privacy"
          render={(props) => <AirPrinterPrivacy {...props} />}
        />

        <Route
          path="/airprinter/terms"
          render={(props) => <AirPrinterTerms {...props} />}
        />

<Route
          path="/smartprint/privacy"
          render={(props) => <AirPrinterPrivacy {...props} />}
        />

        <Route
          path="/smartprint/terms"
          render={(props) => <AirPrinterTerms {...props} />}
        />


<Route
          path="/data-migration/privacy"
          render={(props) => <DataMigrationPrivacy {...props} />}
        />

        <Route
          path="/data-migration/terms"
          render={(props) => <DataMigrationTerms {...props} />}
        />

        <Route
          path="/picker"
          render={(props) => <GooglePicker {...props} />}
        />
        <Route
          path="/air-print-picker"
          render={(props) => <GooglePickerAirPrint {...props} />}
        />
        <Route
          path="/picker-one-drive"
          render={(props) => <OneDrivePicker {...props} />}
        />
        <Route path="/" render={(props) => <Index {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        />
        <Route
          path="/login-page"
          render={(props) => <LoginPage {...props} />}
        />
      
        {/* <Redirect to="/" /> */}
        {/* <Redirect from="/" to="/" /> */}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
